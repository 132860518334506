export default {
  SET_DIRECTOR_RULES: 'SET_DIRECTOR_RULES',
  SET_DIRECTOR_RULE: 'SET_DIRECTOR_RULE',
  SET_RULE: 'SET_RULE',

  SET_TRIGGER_TO_SAVE: 'SET_TRIGGER_TO_SAVE',
  SET_RULES_METADATA: 'SET_RULES_METADATA',

  SET_RULES: 'SET_RULES',
  SET_ENTITIES: 'SET_ENTITIES',
  SET_SEGMENTATION_REPORTS: 'SET_SEGMENTATION_REPORTS',
  SET_CONVERT_LEAD_STATUSES: 'SET_CONVERT_LEAD_STATUSES',
  SET_OPERATORS: 'SET_OPERATORS',
}
