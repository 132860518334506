import Vue from 'vue'
import store from './store'
import router from './router'
import { isSPA } from '@/utils/env'
import VueGtag from 'vue-gtag'
// import posthog from 'posthog-js'

// Plugins
import vuetify, { setSystemTypeColors } from './plugins/vuetify'
import 'chartjs-plugin-datalabels'
import i18n from './plugins/i18n'

// Global application styling
import './assets/scss/theme.scss'

// Main application component
import App from './App.vue'

// https://vuejs.org/v2/api/#productionTip
Vue.config.productionTip = false

if (isSPA) {
  const AppDev = () => import('./AppDev.vue')

  new Vue({
    router,
    vuetify,
    render: (h) => h(AppDev),
  }).$mount('#appDev')
} else {
  document.getElementById('appDevContainer').remove()
}

/**
 * Since there is no message type, we are using
 * property detection to initialize the application
 *
 * event.data has the following properties
 * - systemType
 * - agentId (not used)
 * used to generate the jwt
 * - userId
 * - orgId
 * - permissionName
 * - sessionId
 * - email
 * - firstName
 * - lastName
 * - userName
 * - totp
 * - data.entityKey
 * - data.entityName
 */
window.addEventListener('message', (event) => {
  if (event.data) {
    const { userId, systemType = 'sfdc' } = event.data

    if (userId) {
      let gaId = 'G-V1YR3BMP9J'

      if (window.location.href.indexOf('doordash') > -1) {
        gaId = 'G-WJXN77L245'
      }

      // posthog.init('phc_B59RgphDOPjz6M33wBFEx659wIJaVLaZCssVDcPrd1u', {
      //   api_host: 'https://app.posthog.com',
      // })

      Vue.use(VueGtag, {
        config: {
          id: gaId,
          params: {
            user_id: userId,
            cookie_flags: 'secure;samesite=none',
            // debug_mode: true,
          },
        },
      })

      setUpSystemTypeConfigurations(systemType)

      new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: (h) =>
          h(App, {
            props: {
              context: event.data,
            },
          }),
      }).$mount('#app')
    }
  }
})

function setUpSystemTypeConfigurations(systemType) {
  // data attribute used to apply different styles
  document.querySelector('html').setAttribute('systemType', systemType)
  setSystemTypeGlobalFlag(systemType)
  setSystemTypeColors(systemType)
}

function setSystemTypeGlobalFlag(systemType) {
  Vue.prototype.$sfdc = false
  Vue.prototype.$msdcrm = false
  Vue.prototype[`$${systemType}`] = true
}
