export const downloadFromBlob = (name, data) => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', name)

  document.body.appendChild(link)
  link.click()
  link.remove()
}
