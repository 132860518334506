import Vue from 'vue'
import Router from 'vue-router'
// import { pageview } from 'vue-gtag'
// import store from '../store'

// Routes
import adminRoutes from './admin.routes'
import authRoutes from './auth.routes'
import dashRoutes from './dashboard.routes'
import onEntryRoutes from './on-entry.routes'
import cleanVerifyRoutes from './clean-verify.routes'
// import { trackGTagPage } from '../services/gtag'
// import posthog from 'posthog-js'

Vue.use(Router)

const routes = []

if (process.env.VUE_APP_SPA === 'true') {
  routes.push({
    path: '/',
    name: 'dev-root',
    component: () =>
      import(/* webpackChunkName: "dev-auth" */ '@/views/dev/DevAuth.vue'),
  })

  routes.push({
    path: '/dev/dupegrid',
    name: 'dev-dupegrid',
    component: () =>
      import(
        /* webpackChunkName: "dev-dupegrid" */ '@/views/dev/DevDupeGrid.vue'
      ),
  })
}

routes.push({
  path: '/find-duplicates',
  name: 'find-duplicates',
  component: () =>
    import(
      /* webpackChunkName: "find-duplicates" */ '@/views/FindDuplicates.vue'
    ),
})

routes.push({
  path: '/admin',
  component: () =>
    import(
      /* webpackChunkName: "layout-default" */ '@/views/admin/AdminLayout.vue'
    ),
  meta: {
    requiresAuth: true,
  },
  children: adminRoutes,
})

routes.push({
  path: '/dashboard',
  component: () =>
    import(
      /* webpackChunkName: "layout-dashboard" */ '@/layouts/DashboardLayout.vue'
    ),
  meta: {
    requiresAuth: true,
  },
  children: dashRoutes,
})

routes.push({
  path: '/auth',
  component: {
    render(h) {
      return h('router-view')
    },
  },
  meta: {
    redirectIfAuth: true,
  },
  children: authRoutes,
})

routes.push({
  path: '/merge-history',
  name: 'merge-history',
  component: () =>
    import(
      /* webpackChunkName: "view-merge-history" */ '@/views/merge-history/MergeHistory.vue'
    ),
  meta: {
    pageType: 'MergeHistory',
    pageTitle: 'Merge History',
  },
})

routes.push({
  path: '/record-audit-trail',
  name: 'record-audit-trail',
  component: () =>
    import(
      /* webpackChunkName: "view-record-audit-trail" */ '@/views/RecordAuditTrail.vue'
    ),
  meta: {
    pageType: 'RecordAuditTrail',
    pageTitle: 'Record Audit Trail',
  },
})

routes.push({
  path: '/rule-editor',
  name: 'rule-editor',
  component: () =>
    import(
      /* webpackChunkName: "view-rule-editor" */ '@/views/business-rules-editor/BusinessRuleEditor.vue'
    ),
  meta: {
    pageType: 'RuleEditor',
    pageTitle: 'Rule Editor',
  },
})

routes.push({
  path: '/on-entry',
  component: {
    render(h) {
      return h('router-view')
    },
  },
  children: onEntryRoutes,
})

routes.push({
  path: '/clean-verify',
  name: 'clean-verify',
  component: () =>
    import(
      /* webpackChunkName: "layout-dashboard" */ '@/layouts/DashboardLayout.vue'
    ),
  children: cleanVerifyRoutes,
})

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/',
  routes: [
    ...routes,
    {
      path: '/404',
      name: '404',
      component: () =>
        import(/* webpackChunkName: "view-not-found" */ '@/views/NotFound.vue'),
    },
    {
      path: '*',
      name: 'NotFound',
      component: () =>
        import(/* webpackChunkName: "view-not-found" */ '@/views/NotFound.vue'),
    },
  ],
})

/**
 * Before each route update
 */
// router.afterEach((to) => {
// posthog.capture('$pageview')

// pageview({
//   page_title: to.name,
//   page_path: to.path,
//   page_location: window.location.href,
//   send_page_view: true,
// debug_mode: true,
// })
// pageview.pageview({
//   page_title: to.name,
//   page_path: to.path,
//   page_location: window.location.href,
// })
// const { contextMessage } = store.state.auth

// if (contextMessage && contextMessage.userId) {
//   trackGTagPage({
//     userId: contextMessage.userId,
//     pageType: to.meta.pageType,
//     pagePath: to.path,
//     pageTitle: to.meta.pageTitle,
//   })
// }
// })

export default router
