import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  resolvedStats: [],

  mergedDupeSetsStats: null,
  mergedDupeSets: [],
  mergedDupeSetsFields: [],
  mergedDupeSetsPerPage: 0,
  mergedDupeSetsTotalItems: 0,

  dupeSetsToReviewStats: null,
  dupeSetsToReview: [],
  dupeSetsToReviewPerPage: 0,
  dupeSetsToReviewTotalItems: 0,

  autoMergesToReviewStats: null,
  autoMergesToReview: [],
  autoMergesToReviewPerPage: 0,
  autoMergesToReviewTotalItems: 0,
  isBulkMergeEnabled: false,

  mergeErrors: [],
  mergeErrorsPerPage: 0,
  mergeErrorsTotalItems: 0,
  mergeErrorsTotalPages: 0,

  jobsStats: [],
  jobs: [],
  jobsPerPage: 0,
  jobsTotalItems: 0,
  jobsTotalPages: 0,

  // Advanced filter
  filterFields: [],
  filterTextOperators: [],
  filterDateOperators: [],
  filterNumOperators: [],
  filterOptions: {
    state: [],
  },

  tableHeaders: [
    {
      text: 'MCS',
      value: 'matchConfidenceScore',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Set ID',
      value: 'number',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Required Time',
      value: 'estimatedSecondsToMerge',
      filterType: 'text',
      sortable: false,
      isInDupeSetsToReviewTable: true,
    },
    {
      text: 'Merged',
      value: 'mergedAtDateTime',
      filterType: 'date',
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Merged by',
      value: 'mergedBy',
      filterType: 'text',
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Source',
      value: 'source',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Primary ID',
      value: 'primaryId',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Name',
      value: 'name',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Billing Street',
      value: 'billingStreet',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Billing State',
      value: 'billingState',
      filterType: 'text',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Modified',
      value: 'modifiedAtDateTime',
      filterType: 'date',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
    },
    {
      text: 'Created',
      value: 'createdAtDateTime',
      filterType: 'date',
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
    {
      text: 'Set Owner',
      value: 'ownerName',
      filterType: 'text',
      sortable: false,
      isInAutoMergesToReviewTable: true,
      isInDupeSetsToReviewTable: true,
      isInMergedDupeSetsTable: true,
    },
  ],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
