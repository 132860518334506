import actions from './actions'
import mutations from './mutations'

const state = {
  // TODO: Set these values from postMessage
  dupesGridUrl: process.env.VUE_APP_DUPES_GRID_URL,

  // main toast
  isToastVisible: false,
  toast: {
    color: 'black',
    message: '',
    timeout: 3000,
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
