import * as businessRulesMockApi from '@/api/business-rules/mocks'
import * as businessRulesApi from '@/api/business-rules/rules'

import mutationTypes from './mutation-types'

export default {
  async getDirectorRules({ commit }) {
    const rules = await businessRulesApi.getRules({
      type: businessRulesApi.RULE_TYPES.director,
    })

    commit(mutationTypes.SET_DIRECTOR_RULES, rules)
  },
  async getDirectorRule({ commit, dispatch }, id) {
    try {
      const rule = await businessRulesApi.getRule(id)

      commit(mutationTypes.SET_DIRECTOR_RULE, rule)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
    }
  },
  async getRuleEntityMetadata({ commit }, { ruleType, entities }) {
    const metadataByEntity = await businessRulesApi.getRuleEntityMetadata({
      ruleType,
      entities,
    })

    commit(mutationTypes.SET_RULES_METADATA, { ruleType, metadataByEntity })
  },
  async getRule({ commit }, id) {
    const rule = await businessRulesApi.getRule(id)
    commit(mutationTypes.SET_RULE, rule)
  },
  triggerSaveCurrentRule({ commit }, ruleName) {
    commit(mutationTypes.SET_TRIGGER_TO_SAVE, {
      ruleName,
      isTriggeringSave: true,
    })
  },
  resetTriggerSaveCurrentRule({ commit }, ruleName) {
    commit(mutationTypes.SET_TRIGGER_TO_SAVE, {
      ruleName,
      isTriggeringSave: false,
    })
  },
  assignLocalRuleToDirector({ commit }, { rule }) {
    commit(mutationTypes.SET_RULE, rule)
  },

  async createSegmentationRule(
    { commit, dispatch },
    { directorRuleId, rule, segmentations }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createSegmentationRule(
        directorRuleId,
        rule,
        segmentations
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateSegmentationRule({ commit, dispatch }, { rule, segmentations }) {
    try {
      const ruleUpdated = await businessRulesApi.updateSegmentationRule(
        rule,
        segmentations
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async createMatchCriteriaRule(
    { commit, dispatch },
    { directorRuleId, rule, criterias }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createMatchCriteriaRule(
        directorRuleId,
        rule,
        criterias
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateMatchCriteriaRule({ commit, dispatch }, { rule, criterias }) {
    try {
      const ruleUpdated = await businessRulesApi.updateMatchCriteriaRule(
        rule,
        criterias
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async createAssignmentRule(
    { commit, dispatch },
    { directorRuleId, rule, assignments }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createAssignmentRule(
        directorRuleId,
        rule,
        assignments
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateAssignmentRule({ commit, dispatch }, { rule, assignments }) {
    try {
      const ruleUpdated = await businessRulesApi.updateAssignmentRule(
        rule,
        assignments
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async createPrimaryRule(
    { commit, dispatch },
    { directorRuleId, rule, conditions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createPrimaryRule(
        directorRuleId,
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updatePrimaryRule({ commit, dispatch }, { rule, conditions }) {
    try {
      const ruleUpdated = await businessRulesApi.updatePrimaryRule(
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async createRollUpRule(
    { commit, dispatch },
    { directorRuleId, rule, conditions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createRollUpRule(
        directorRuleId,
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateRollUpRule({ commit, dispatch }, { rule, conditions }) {
    try {
      const ruleUpdated = await businessRulesApi.updateRollUpRule(
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async createWriteRule(
    { commit, dispatch },
    { directorRuleId, rule, conditions }
  ) {
    try {
      const ruleCreated = await businessRulesApi.createWriteRule(
        directorRuleId,
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleCreated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async updateWriteRule({ commit, dispatch }, { rule, conditions }) {
    try {
      const ruleUpdated = await businessRulesApi.updateWriteRule(
        rule,
        conditions
      )

      commit(mutationTypes.SET_RULE, ruleUpdated)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  getOperators: async ({ commit, dispatch }) => {
    try {
      const data = await businessRulesMockApi.getOperators()

      commit(mutationTypes.SET_OPERATORS, data)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:getOperators', error)
    }
  },
  getEntities: async ({ commit, dispatch }) => {
    try {
      const entities = await businessRulesApi.getEntities()

      commit(mutationTypes.SET_ENTITIES, entities)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:getEntities', error)
    }
  },
  getConvertLeadStatuses: async ({ commit, dispatch }) => {
    try {
      const data = await businessRulesMockApi.getConvertLeadStatuses()

      commit(mutationTypes.SET_CONVERT_LEAD_STATUSES, data)

      return data
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:getConvertLeadStatuses', error)

      return false
    }
  },
}
