export default {
  SET_MERGED_DUPESETS_STATS: 'SET_MERGED_DUPESETS_STATS',
  SET_MERGED_DUPESETS: 'SET_MERGED_DUPESETS',

  SET_DUPE_SETS_TO_REVIEW_STATS: 'SET_DUPE_SETS_TO_REVIEW_STATS',
  SET_DUPE_SETS_TO_REVIEW: 'SET_DUPE_SETS_TO_REVIEW',

  SET_AUTOMERGES_TO_REVIEW_STATS: 'SET_AUTOMERGES_TO_REVIEW_STATS',
  SET_AUTOMERGES_TO_REVIEW: 'SET_AUTOMERGES_TO_REVIEW',

  SET_RESOLVED_STATS: 'SET_RESOLVED_STATS',

  SET_JOBS_STATS: 'SET_JOBS_STATS',
  SET_JOBS: 'SET_JOBS',

  SET_MERGE_ERRORS: 'SET_MERGE_ERRORS',

  SET_FILTER_FIELDS: 'SET_FILTER_FIELDS',
  SET_FILTER_TEXT_OPERATORS: 'SET_FILTER_TEXT_OPERATORS',
  SET_FILTER_DATE_OPERATORS: 'SET_FILTER_DATE_OPERATORS',
  SET_FILTER_NUM_OPERATORS: 'SET_FILTER_NUM_OPERATORS',
  SET_FILTER_OPTIONS_STATES: 'SET_FILTER_OPTIONS_STATES',
}
