import mutationTypes from './mutation-types'
import { getMe } from '@/api/admin/users'
import { signIn } from '@/api/auth'

export default {
  setJwt({ commit }, jwt) {
    commit(mutationTypes.SET_JWT, jwt)
  },
  setAgentId({ commit }, id) {
    commit(mutationTypes.SET_AGENT_ID, id)
  },
  setContextMessage({ commit }, message) {
    commit(mutationTypes.SET_CONTEXT_MESSAGE, message)
  },
  signOut({ commit }) {
    commit(mutationTypes.SIGN_OUT)
  },
  setSessionExpired({ commit }, value) {
    commit(mutationTypes.SET_SESSION_EXPIRED, value)
  },
  async getMe({ commit, dispatch }) {
    try {
      const response = await getMe()

      commit(mutationTypes.SET_CURRENT_USER, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:GetMe', error)
    }
  },
  async signIn({ commit, dispatch }, { username, password }) {
    try {
      const jwt = await signIn({
        username,
        password,
      })

      await dispatch('getMe')
      commit(mutationTypes.SET_JWT, jwt)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:SignIn', error)
    }
  },
}
