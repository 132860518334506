import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'
import { timeAgo, formatDate } from '@/utils/dates'
import store from '@/store'

export const entityColors = {
  Account: 'ap-dark-blue',
  Lead: 'ap-green',
  All: 'ap-red',
}

export const RULE_TYPES = {
  director: 'Director',
  multiMap: 'MultiMap',
  segmentation: 'Filter',
  matchCriteria: 'MatchCriteria',
  assignment: 'Assignment',
  primary: 'SelectPrimary',
  rollUp: 'RollUp',
  write: 'Write',
  presentation: 'Presentation',
}

const RULE_RESPONSE_PATHS = {
  id: 'id',
  name: 'name',
  type: 'type',
  description: 'description',
  entities: 'entities',
  status: 'status',
  modifiedAt: 'modified',
  agentId: 'agent_id',
}

function ruleResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    descriptionTruncated:
      mappedItem.description?.length < 70
        ? mappedItem.description
        : mappedItem.description?.substr(0, 70) + '...',
    isStatusActive: mappedItem.status === 'active',
    isStatusInactive: mappedItem.status === 'inactive',
    modifiedAtFormatted: formatDate(
      mappedItem.modifiedAt,
      'dd LLL yyyy | HH:mm'
    ),
    modifiedAtTimeAgo: timeAgo(mappedItem.modifiedAt),
  }
}

function entitiesResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    actions: mapToArray(mappedItem.actions, ENTITIES_ACTIONS_RESPONSE_PATH),
  }
}

const RULES_REQUEST_PATHS = {
  per_page: 'perPage',
  page: 'page',
  order_by_field: 'orderByField',
  order: 'order_by',
  type: 'type',
}

const ENTITIES_RESPONSE_PATH = {
  label: 'label',
  labelPlural: 'label_plural',
  name: 'name',
  type: 'type',
  actions: 'actions',
  conversion: 'conversion',
}

const ENTITIES_ACTIONS_RESPONSE_PATH = {
  name: 'name',
}

const CRITERIA_ENTITIES_RESPONSE_PATHS = {
  children: 'fields',
  name: 'name',
}

const CRITERIA_ENTITIES_FIELDS_RESPONSE_PATHS = {
  id: 'name',
  name: 'name',
}

function criteriaEntitiesResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    children: mapToArray(
      mappedItem.children,
      CRITERIA_ENTITIES_FIELDS_RESPONSE_PATHS
    ),
  }
}

const CRITERIA_OPERATORS_RESPONSE_PATHS = {
  isEnabled: 'enabled',
  label: 'label',
  name: 'name',
  options: 'options',
}

export const getRule = (id) => {
  return http.$get('/rule/rules/' + id).then((rule) => {
    return mapResponseRule(rule)
  })
}

export const getRules = (payload) => {
  const params = mapToObject(payload, RULES_REQUEST_PATHS)

  return http.$get('/rule/rules', { params }).then((response) => {
    const rules = []

    response.data.forEach((rule) => {
      if (rule) {
        rules.push(mapResponseRule(rule))
      }
    })

    return rules
  })
}

function mapResponseRule(rule) {
  switch (rule.type) {
    case RULE_TYPES.director:
      return mapResponseDirectorRule(rule)
    case RULE_TYPES.segmentation:
      return mapResponseFilterRule(rule)
    case RULE_TYPES.multiMap:
      return mapResponseMultiMapRule(rule)
    case RULE_TYPES.presentation:
      return mapResponsePresentationRule(rule)
    case RULE_TYPES.matchCriteria:
      return mapResponseMatchingRule(rule)
    case RULE_TYPES.assignment:
      return mapResponseAssignmentRule(rule)
    case RULE_TYPES.primary:
      return mapResponsePrimaryRule(rule)
    case RULE_TYPES.rollUp:
      return mapResponseRollUpRule(rule)
    case RULE_TYPES.write:
      return mapResponseWriteRule(rule)
    default:
      return null
  }
}

function mapResponseDirectorRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    autoMergeEnabled: response.rule.auto_merge_enabled,
    rules: rule.rules.reduce((acc, el) => {
      return {
        ...acc,
        [el.rule_type]: { id: el.rule_id },
      }
    }, {}),
  }
}

function mapResponseFilterRule(response) {
  const segmentations = []
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  for (const prop in rule) {
    const entity = rule[prop]

    segmentations.push({
      entityName: prop,
      conditions: entity.config[0].config.conditions.map((condition) => {
        return {
          operator: condition.algo,
          field: condition.field,
          value: condition.value,
        }
      }),
    })
  }

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    segmentations,
  }
}

function mapResponseMatchingRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    rule:
      typeof response.rule === 'string'
        ? JSON.parse(response.rule)
        : response.rule,
  }
}

function mapResponseAssignmentRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    assignments: response.rule.rules
      .filter((rule) => {
        return (
          rule.operators.findIndex(
            (operator) => operator[0] === '=' && operator[1] === 'mcs_category'
          ) > -1
        )
      })
      .map((rule) => {
        return {
          mcs: rule.operators.find(
            (operator) => operator[0] === '=' && operator[1] === 'mcs_category'
          )[2],
          userId: rule.assign_to.user_id,
          roleId: rule.assign_to.role_id,
        }
      }),
  }
}

function mapResponsePrimaryRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    criterias: response.rule.rules.reduce((e, entity) => {
      return {
        ...e,
        [entity.entity_name]: entity.conditions.map((criteria) => {
          return criteria.operators.reduce((acc, condition, index) => {
            if (index === 0) {
              return acc
            }

            return [
              ...acc,
              {
                field: condition[1],
                operator: condition[0],
                value: condition[2],
              },
            ]
          }, [])
        }),
      }
    }, {}),
  }
}

function mapResponseRollUpRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    conditions: response.rule.rules.map((el) => {
      const isPrimaryCondition = el.when?.record?.find(
        (condition) => condition[0] === 'IsPrimary'
      )

      return {
        finalRecordEntity: el.final_record_entity,
        finalRecordEntityColor: entityColors[el.final_record_entity],
        lookUpEntities: el.look_up_entities,
        whenByColumn: el.when?.column?.map((column) => {
          return {
            field: column[1],
            operator: column[0],
            value: column[2],
          }
        }),
        whenByRecord: {
          isPrimary: isPrimaryCondition?.[1] || false,
          conditions: el.when?.record?.reduce((acc, record) => {
            if (record[0] !== 'IsPrimary') {
              return [
                ...acc,
                {
                  field: record[1],
                  operator: record[0],
                  value: record[2],
                },
              ]
            }
            return acc
          }, []),
        },
        fieldsToRollUp: el.then.map((field) => {
          return {
            name: field.field_name,
            operator: field.operator,
            value:
              field.order_by_field ||
              field.order_by_data ||
              field.value ||
              field.from_field_name,
            overridePrimary: field.override_primary,
          }
        }),
      }
    }),
  }
}

function mapResponseWriteRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    conditions: response.rule.rules.map((el) => {
      return {
        entity: el.entity_name,
        actions: el.operators.map((operator) => {
          const action = {
            name: operator.operator_name,
          }

          if (operator.operator_name === 'convert') {
            action.convertedStatus = operator.arguments.converted_status
            action.createOpportunity = operator.arguments.create_opportunity
            action.conditions = operator.when.map((conditions) => {
              return conditions.map((condition) => {
                return {
                  field: condition[1],
                  operator: condition[0],
                  value: condition[2],
                }
              })
            })
          }

          return action
        }),
      }
    }),
  }
}

function mapResponseMultiMapRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    entities: rule.entity_field_maps.map((el) => el.entity_name),
    primaryEntity: rule.primary_entity,
  }
}

function mapResponsePresentationRule(response) {
  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
  }
}

export const createDirectorRule = (payload) => {
  return http
    .$post('/rule/rules', mapRequestDirectorRule(payload))
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateDirectorRule = (payload) => {
  return http
    .$post(`/rule/rules/${payload.id}`, mapRequestDirectorRule(payload))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestDirectorRule(directorRule) {
  return {
    id: directorRule.id,
    agent_id: directorRule.agentId,
    name: directorRule.name,
    description: directorRule.description,
    status: directorRule.status,
    type: directorRule.type,
    entities: directorRule.entities,
    rule: {
      auto_merge_enabled: directorRule.autoMergeEnabled || false,
      rules: Object.entries(directorRule.rules).map(([ruleType, rule]) => {
        return {
          rule_id: rule.id,
          rule_type: ruleType,
        }
      }),
    },
  }
}

export const createSegmentationRule = (directorRuleId, rule, segmentations) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestSegmentationRule(rule, segmentations)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateSegmentationRule = (rule, segmentations) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestSegmentationRule(rule, segmentations)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestSegmentationRule(rule, segmentations) {
  const ruleConfig = {}

  segmentations.forEach((segmentation) => {
    if (segmentation.conditions.length > 0) {
      ruleConfig[segmentation.entityName] = {
        entity_name: segmentation.entityName,
        class: 'OrClause',
        config: [
          {
            class: 'AndClause',
            config: {
              conditions: segmentation.conditions.map((condition) => {
                return {
                  algo: condition.operator,
                  field: condition.field,
                  value: condition.value,
                  case_sensitive: false,
                }
              }),
            },
          },
        ],
      }
    }
  })

  return {
    id: rule.id,
    name: rule.name,
    agent_id: store.state.auth.agentId,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createMatchCriteriaRule = (directorRuleId, rule, criterias) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestMatchCriteriaRule(rule, criterias)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateMatchCriteriaRule = (rule, criterias) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestMatchCriteriaRule(rule, criterias)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestMatchCriteriaRule(rule, criterias) {
  const ruleConfig = {
    class: 'OrClause',
    config: [
      ...criterias.map((criteria) => ({
        class: 'AndClause',
        config: {
          conditions: criteria.conditions,
          confidence: criteria.confidence,
          rank_modifier: [],
        },
      })),
    ],
    cross_object: {
      Account: ['Account', 'Lead'],
      Lead: ['Account', 'Lead'],
    },
  }

  return {
    id: rule.id,
    name: rule.name,
    agent_id: store.state.auth.agentId,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createAssignmentRule = (directorRuleId, rule, assignments) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestAssignmentRule(rule, assignments)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateAssignmentRule = (rule, assignments) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestAssignmentRule(rule, assignments)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestAssignmentRule(rule, assignments) {
  const ruleConfig = {
    rules: [
      ...assignments.map((el) => {
        return {
          operators: [['=', 'mcs_category', el.matchConfidenceScoreCode]],
          assign_to: {
            role_id: el.assignTo.roleId,
            user_id: el.assignTo.id,
          },
          actions: ['assign_to'],
        }
      }),
    ],
    exceptions: [],
  }

  return {
    id: rule.id,
    name: rule.name,
    agent_id: store.state.auth.agentId,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createPrimaryRule = (directorRuleId, rule, conditions) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestPrimaryRule(rule, conditions)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updatePrimaryRule = (rule, conditions) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestPrimaryRule(rule, conditions))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestPrimaryRule(rule, criteriasByEntity) {
  const ruleConfig = {
    rules: [],
  }

  Object.keys(criteriasByEntity).forEach((entityName) => {
    const criterias = []

    criteriasByEntity[entityName].forEach((conditions) => {
      criterias.push({
        operators: [
          ['column_has_only', 'source', [entityName]],
          ...conditions.map((condition) => {
            return [condition.operator, condition.field, condition.value]
          }),
        ],
        action: ['select_primary', entityName],
      })
    })

    ruleConfig.rules.push({
      entity_name: entityName,
      conditions: criterias,
    })
  })

  return {
    id: rule.id,
    name: rule.name,
    agent_id: store.state.auth.agentId,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createRollUpRule = (directorRuleId, rule, rollUp) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestRollUpRule(rule, rollUp)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateRollUpRule = (rule, rollUp) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestRollUpRule(rule, rollUp))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestRollUpRule(rule, items) {
  const ruleConfig = {
    rules: [],
  }

  items.forEach((item) => {
    let whenCondition = {}
    let whenRecordConditions = []
    let whenColumnConditions = []

    if (item.whenByRecord?.conditions?.length > 0) {
      whenRecordConditions = [
        ['IsPrimary', item.whenByRecord?.isPrimary],
        ...item.whenByRecord?.conditions?.map((el) => [
          el.operator,
          el.field,
          el.value,
        ]),
      ]

      whenCondition.record = whenRecordConditions
    }

    if (item.whenByColumn?.length > 0) {
      whenColumnConditions = item.whenByColumn?.map((el) => [
        el.operator,
        el.field,
        el.value,
      ])

      whenCondition.column = whenColumnConditions
    }

    const rule = {
      final_record_entity: item.finalRecordEntity,
      look_up_entities: item.lookUpEntities,
      when: whenCondition,
      then: item.fieldsToRollUp.map((el) => {
        var field = {
          field_name: el.name,
          operator: el.operator,
          override_primary: el.overridePrimary,
        }

        if (['GetNewestValue', 'GetOldestValue'].includes(el.operator)) {
          field = {
            ...field,
            order_by_field: el.value,
          }
        } else if (el.operator === 'GetHighestOrder') {
          field = {
            ...field,
            order_by_data: el.value,
          }
        } else if (el.operator === 'SetValueFromRecord') {
          field = {
            ...field,
            from_field_name: el.value,
          }
        } else {
          field = {
            ...field,
            value: el.value,
          }
        }

        return field
      }),
    }

    ruleConfig.rules.push(rule)
  })

  return {
    id: rule.id,
    name: rule.name,
    agent_id: store.state.auth.agentId,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createWriteRule = (directorRuleId, rule, conditions) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestWriteRule(rule, conditions)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateWriteRule = (rule, conditions) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestWriteRule(rule, conditions))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestWriteRule(rule, conditions) {
  const ruleConfig = {
    rules: conditions.map((condition) => {
      return {
        entity_name: condition.entity,
        operators: condition.actions.map((action) => {
          const item = {
            operator_name: action.name,
          }

          if (action.name === 'convert') {
            item.arguments = {
              converted_status: action.convertedStatus,
              create_opportunity: action.createOpportunity,
            }

            if (action.conditions.length > 0) {
              item.when = action.conditions.map((conditions) => {
                return conditions.map((condition) => [
                  condition.operator,
                  condition.field,
                  condition.value,
                ])
              })
            }
          }

          return item
        }),
      }
    }),
  }

  return {
    id: rule.id,
    name: rule.name,
    agent_id: store.state.auth.agentId,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const deleteRule = (ruleId) => http.$delete(`/rule/rules/${ruleId}`)

export const getEntities = () => {
  return http.$get('/rule/entities').then((response) => {
    return mapToArray(response.entities, ENTITIES_RESPONSE_PATH, {
      extraMapping: entitiesResponseExtraMapping,
    })
  })
}

const FIELDS_RESPONSE_PATHS = {
  name: 'name',
  label: 'label',
  operators: 'operators',
}

const OPERATORS_RESPONSE_PATHS = {
  label: 'label',
  operator: 'operator',
  values: 'values',
}

export const getRuleEntityMetadata = ({ ruleType, entities }) => {
  return http
    .$get(`/rule/settings/entities/${ruleType}`, {
      params: { entity: entities.join(',') },
    })
    .then((response) => {
      if (ruleType === RULE_TYPES.rollUp) {
        const entities = response.roll_up_entities.map((el) => {
          return {
            name: el.name,
            label: el.label,
          }
        })

        const rollUpFieldsPerEntity = {}
        const rollUpFieldsPerEntityObject = {}

        response.roll_up_entities.forEach((entity) => {
          rollUpFieldsPerEntity[entity.name] = entity.fields.map((field) => {
            const operatorsObj = {}
            const operators = []

            field.roll_up_operators.forEach((operator) => {
              const valuesObj = {}
              if (
                Array.isArray(operator.values) &&
                typeof operator.values[0] === 'object'
              ) {
                operator.values.forEach((field) => {
                  valuesObj[field.name] = {
                    name: field.name,
                    label: field.label,
                    type: field.type,
                  }
                })
              }

              const operatorMapped = {
                label: operator.label,
                name: operator.name,
                type: operator.type,
                multiple: operator.multiple,
                values: operator.values,
                valuesObj,
                valuesLabel: operator.values_label,
              }

              operators.push(operatorMapped)
              operatorsObj[operator.name] = operatorMapped
            })

            rollUpFieldsPerEntityObject[entity.name] = {
              ...rollUpFieldsPerEntityObject[entity.name],
              [field.name]: {
                label: field.label,
                name: field.name,
                type: field.type,
                operatorsObj,
                operators,
              },
            }

            return {
              label: field.label,
              name: field.name,
              type: field.type,
              operators: field.roll_up_operators.map((op) => {
                return {
                  label: op.label,
                  name: op.name,
                  type: op.type,
                  multiple: op.multiple,
                  values: op.values,
                  valuesLabel: op.values_label,
                }
              }),
            }
          })
        })

        let whenColumn = {}
        response.when_to_apply?.column.forEach((field) => {
          let operatorsObj = {}
          const operators = []

          field.operators.forEach((operator) => {
            const operatorMapped = {
              label: operator.label,
              name: operator.name,
              type: operator.type,
              multiple: operator.multiple,
              values: operator.values,
              valuesLabel: operator.values_label,
            }

            operators.push(operatorMapped)
            operatorsObj[operator.name] = operatorMapped
          })

          whenColumn[field.field_name] = {
            ...whenColumn,
            fieldLabel: field.field_label,
            fieldName: field.field_name,
            fieldType: field.field_type,
            operatorsObj,
            operators,
          }
        })

        let whenRecord = {}
        response.when_to_apply?.record.forEach((field) => {
          let operatorsObj = {}
          const operators = []

          field.operators.forEach((operator) => {
            const operatorMapped = {
              label: operator.label,
              name: operator.name,
              type: operator.type,
              multiple: operator.multiple,
              values: operator.values,
              valuesLabel: operator.values_label,
            }

            operators.push(operatorMapped)
            operatorsObj[operator.name] = operatorMapped
          })

          whenRecord[field.field_name] = {
            fieldLabel: field.field_label,
            fieldName: field.field_name,
            fieldType: field.field_type,
            operatorsObj,
            operators,
          }
        })

        return {
          entities,
          rollUpFieldsPerEntity,
          rollUpFieldsPerEntityObject,
          whenToApplyObj: {
            column: whenColumn,
            record: whenRecord,
          },
          whenToApply: {
            column: response.when_to_apply?.column.map((field) => {
              return {
                fieldLabel: field.field_label,
                fieldName: field.field_name,
                fieldType: field.field_type,
                operators: field.operators.map((operator) => {
                  return {
                    label: operator.label,
                    name: operator.name,
                    type: operator.type,
                    multiple: operator.multiple,
                    values: operator.values,
                    valuesLabel: operator.value_label,
                  }
                }),
              }
            }),
            record: response.when_to_apply?.record.map((field) => {
              return {
                fieldLabel: field.field_label,
                fieldName: field.field_name,
                fieldType: field.field_type,
                operators: field.operators.map((operator) => {
                  return {
                    label: operator.label,
                    name: operator.name,
                    type: operator.type,
                    multiple: operator.multiple,
                    values: operator.values,
                    valuesLabel: operator.value_label,
                  }
                }),
              }
            }),
          },
        }
      }
      if (ruleType === RULE_TYPES.write) {
        const actionsByEntity = {}

        response.rule.forEach((entity) => {
          actionsByEntity[entity.name] = entity.operators.map((operator) => {
            return {
              name: operator.name,
              label: operator.label,
            }
          })
        })

        return {
          entities: response.rule.map((el) => {
            return {
              name: el.name,
              label: el.label,
            }
          }),
          actionsByEntity,
          convertedStatus: response.converted_status.map((el) => {
            return {
              name: el.name,
              label: el.label,
            }
          }),
          convertConditions: response.when.map((el) => {
            return {
              name: el.name,
              label: el.label,
              operators: el.operators.map((operator) => {
                return {
                  name: operator.name,
                  label: operator.label,
                }
              }),
            }
          }),
        }
      } else {
        const entitiesMetadata = {}
        const entitiesKeys = Object.keys(response)
        entitiesKeys.forEach((key) => {
          const metadata = response[key]

          if (ruleType === RULE_TYPES.segmentation) {
            entitiesMetadata[key] = mapToArray(
              metadata.fields,
              FIELDS_RESPONSE_PATHS,
              {
                extraMapping: (mappedItem) => {
                  return {
                    ...mappedItem,
                    operators: mapToArray(
                      mappedItem.operators,
                      OPERATORS_RESPONSE_PATHS
                    ),
                  }
                },
              }
            )
          } else if (ruleType === RULE_TYPES.matchCriteria) {
            const entities = mapToArray(
              metadata.entities,
              CRITERIA_ENTITIES_RESPONSE_PATHS,
              criteriaEntitiesResponseExtraMapping
            )
            const operators = mapToArray(
              metadata.operators,
              CRITERIA_OPERATORS_RESPONSE_PATHS
            )

            entitiesMetadata[key] = {
              entities,
              operators,
            }
          } else if (ruleType === RULE_TYPES.assignment) {
            const users = metadata.assign_to
              .filter((user) => user.user_name)
              .map((user) => {
                return {
                  id: user.user_id,
                  username: user.user_name,
                  roleId: user.role_id,
                  roleName: user.role_name,
                }
              })

            entitiesMetadata[key] = {
              users,
            }
          } else if (ruleType === RULE_TYPES.primary) {
            entitiesMetadata[key] = metadata.operators.fields.filter(
              (el) => el.name !== 'source'
            )
          }
          return null
        })

        return entitiesMetadata
      }
    })
}
